export class OrderPix {
  public id: number
  public txId: string
  public image: string
  public copyPasteCode: string
  public expiresAt?: Date

  public constructor(data: any = {}) {
    this.id = data.id || 0
    this.txId = data.txId || ""
    this.image = data.image || ""
    this.copyPasteCode = data.copyPasteCode || ""

    if (data.expiresAt) {
      this.expiresAt = new Date(data.expiresAt)
    }
  }
}
