import React from "react"
import { Flex, Text, Checkbox, Badge } from "@chakra-ui/react"
import Formatter from "../../util/formatter"

interface CheckboxCardProps {
  id: number
  licensePlate: string
  info: string
  date: string
  status: string
  statusCode: string
  dueDate: string
  value: string
  checked: boolean
  onChange: (id: number, checked: boolean) => void
}

const getBadgeColor = (statusCode: string) => {
  if (statusCode === "paid") return { colorScheme: "green", color: "#38A169" }
  if (statusCode === "processing")
    return { colorScheme: "gray", color: "#8E99AB" }
  if (statusCode === "overdue") return { colorScheme: "red", color: "#C53030" }
  if (statusCode === "ontime") return { colorScheme: "blue", color: "#7694FF" }
  if (statusCode === "added") return { colorScheme: "green", color: "#38A169" }
  if (statusCode === "completed")
    return { colorScheme: "green", color: "#38A169" }

  return { colorScheme: "white", color: "#8E99AB" }
}

const getBorderColor = (checked: boolean) => {
  if (checked === true) return { border: "1px solid #00335B" }
  return { border: "1px solid #E2E8F0" }
}

export default function CheckboxCard(props: CheckboxCardProps) {
  const badgeColor = getBadgeColor(props.statusCode)
  const borderColor = getBorderColor(props.checked)

  return (
    <Flex
      w="100%"
      p="0.625rem 0.750rem"
      borderRadius="0.5rem"
      border={borderColor?.border}
      gap="0.750rem"
      fontFamily="Raleway"
    >
      <Flex alignItems="flex-start">
        <Checkbox
          size="lg"
          colorScheme="facebook"
          isChecked={props.checked}
          onChange={(e) => props.onChange(props.id, e.target.checked)}
        ></Checkbox>
      </Flex>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Flex direction="column">
          <Text
            as="span"
            fontSize="0.750rem"
            fontWeight="600"
            color="#2D3748"
            translate="no"
          >
            {props.licensePlate}
          </Text>
          <Text as="span" fontSize="0.750rem" fontWeight="400" color="#8E99AB">
            {props.info}
          </Text>
          <Text
            as="span"
            fontSize="0.750rem"
            fontWeight="400"
            color="#8E99AB"
            translate="no"
          >
            {props.date}
          </Text>
        </Flex>
        <Flex direction="column">
          <Flex alignItems="center" gap="0.375rem">
            <Badge
              variant="subtle"
              colorScheme={badgeColor.colorScheme}
              color={badgeColor.color}
              borderRadius="0.25rem"
              textTransform="none"
              p="0.125rem 0.25rem"
            >
              {Formatter.formatStatus(props.status)}
            </Badge>
            <Text
              as="span"
              fontSize="0.750rem"
              fontWeight="700"
              color="#2D3748"
            >
              R$ {props.value}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <Text
              as="span"
              fontSize="0.750rem"
              fontWeight="400"
              color="#8E99AB"
            >
              Venc. {props.dueDate}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
