import { format } from "date-fns"
import { Charge } from "../entities"

export default class Formatter {
  public static formatCurrency(value: number) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value / 100)
  }

  public static formatDate(date: Date): string {
    if (typeof date === "string") return date
    return date?.toLocaleDateString("pt-br") || ""
  }

  public static formatDateForInput(
    date: number | string | Date | null
  ): string {
    if (!date) return ""

    const dateFormated = new Date(date)
    if (isNaN(dateFormated.getTime())) return ""
    return format(dateFormated, "yyyy-MM-dd")
  }

  public static formatDateCsg(date: Date): string {
    if (typeof date === "string") return date
    return `${date.getDate()}/${Formatter.getMonthName(
      date.getMonth()
    )}/${date.getFullYear()}`
  }

  public static formatDateTime(date: Date): string {
    return (
      date?.toLocaleDateString("pt-br") +
        " " +
        date.toLocaleTimeString("pt-br") || ""
    )
  }

  public static getMonthName(month: number): string {
    if (month === 1) return "Janeiro"
    if (month === 2) return "Fevereiro"
    if (month === 3) return "Março"
    if (month === 4) return "Abril"
    if (month === 5) return "Maio"
    if (month === 6) return "Junho"
    if (month === 7) return "Julho"
    if (month === 8) return "Agosto"
    if (month === 9) return "Setembro"
    if (month === 10) return "Outubro"
    if (month === 11) return "Novembro"
    if (month === 12) return "Dezembro"

    return month.toString()
  }

  public static formatStatus(status: string): string {
    if (status === "open") return "Aberto"
    if (status === "pending") return "Pendente"
    if (status === "paid") return "Pago"
    if (status === "complete") return "Finalizado"
    if (status === "completed") return "Finalizado"
    if (status === "free") return "Gratuito"
    return status
  }

  public static formatChargeTotal(charge: Charge) {
    const total = charge.rateAmount + charge.fee - charge.discount
    return Formatter.formatCurrency(total)
  }

  public static formatPartialDocument(document: string) {
    let partialDocument = document.replace(document.substring(0, 3), "***")
    partialDocument = partialDocument.replace(document.slice(-2), "**")
    return partialDocument
  }
}
