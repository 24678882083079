import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink, useParams } from "react-router-dom"
import { Flex, Text, Button, Link as ChakraLink, Image } from "@chakra-ui/react"
import { GoLinkExternal } from "react-icons/go"
import Layout from "../../components/layout/Layout/Layout"
import { AuthService } from "../../services"

export default function BlockedAccess() {
  const params = useParams()

  const [isUserIpBlocked, setIsUserIpBlocked] = useState(false)
  const [ip, setIp] = useState("")

  useEffect(() => {
    if (params.type === "usuario") {
      setIsUserIpBlocked(true)
    } else {
      getIp()
      setIsUserIpBlocked(false)
    }
  }, [params.type])

  const getIp = () => {
    AuthService.getIp().then((clientIp) => setIp(clientIp))
  }

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex w="100%" direction="column">
        <Flex w="100%" justifyContent="center">
          <Image
            maxW="113.9px"
            maxH="68.9px"
            src="/images/csg-logo-blue.png"
            alt="Logotipo da CSG"
          ></Image>
        </Flex>
        <Flex
          w="100%"
          maxWidth="515px"
          minH="500px"
          direction="column"
          justifyContent="center"
          gap="1rem"
          m="auto 0"
          fontFamily="Raleway"
        >
          <Flex direction="column" alignItems="center">
            <Flex>
              <Image src="/images/WarningRedIcon.svg" alt="icon" />
            </Flex>

            {isUserIpBlocked && (
              <Flex direction="column" alignItems="center">
                <Text
                  mt="0.75rem"
                  mb="0.75rem"
                  textAlign="center"
                  color="#2D3748"
                  fontSize="1.5rem"
                  fontWeight="700"
                >
                  Seu usuário foi bloqueado.
                </Text>
                <Text
                  textAlign="center"
                  color="#4A5568"
                  fontSize="1.13rem"
                  fontWeight="500"
                >
                  Entre em contato com a CSG e solicite a liberação.
                </Text>
                <ChakraLink
                  w="full"
                  mt="2.25rem"
                  as={ReactRouterLink}
                  to="/contato"
                >
                  <Button
                    w="full"
                    size="lg"
                    fontSize="1rem"
                    bg="#00335B"
                    color="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap="0.5rem"
                    _hover={{ bg: "#003f70", transition: "0.3s" }}
                  >
                    Falar com a CSG
                    <GoLinkExternal />
                  </Button>
                </ChakraLink>
              </Flex>
            )}

            {!isUserIpBlocked && (
              <Flex direction="column" alignItems="center">
                <Text
                  mt="0.75rem"
                  mb="0.75rem"
                  textAlign="center"
                  color="#2D3748"
                  fontSize="1.5rem"
                  fontWeight="700"
                >
                  Seu IP ({ip}) foi temporariamente bloqueado.
                </Text>
                <Text
                  textAlign="center"
                  color="#4A5568"
                  fontSize="1.13rem"
                  fontWeight="500"
                >
                  Aguarde alguns minutos e tente novamente.
                </Text>
                <ChakraLink
                  w="full"
                  mt="2.25rem"
                  as={ReactRouterLink}
                  to="/login"
                >
                  <Button
                    w="full"
                    size="lg"
                    fontSize="1rem"
                    bg="#00335B"
                    color="white"
                    _hover={{ bg: "#003f70", transition: "0.3s" }}
                  >
                    Tentar novamente
                  </Button>
                </ChakraLink>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex minH="100px" m="0 auto" alignItems="flex-end" fontFamily="Raleway">
        {!isUserIpBlocked && (
          <ChakraLink
            as={ReactRouterLink}
            to="/cadastrar"
            fontSize="0.875rem"
            color="#3531C6"
            fontWeight="700"
            ml="0.25rem"
          >
            Falar com a CSG
          </ChakraLink>
        )}
      </Flex>
    </Layout>
  )
}
