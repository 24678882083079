import React, { useEffect, useState } from "react"
import { Flex, Text, VStack, useToast } from "@chakra-ui/react"
import PaymentTypeCreditCard from "../../components/paymentCard/PaymentTypeCreditCard"
import PaymentTypePix from "../../components/paymentCard/PaymentTypePix"
import Layout from "../../components/layout/Layout/Layout"
import CheckoutStorage from "../../util/checkoutStorage"
import {
  CheckoutCardInput,
  CheckoutInput,
} from "../../entities/csg/CheckoutInput"
import { OrderService } from "../../services/csg"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import { UserService } from "../../services"
import UserStorage from "../../util/userStorage"

export default function PaymentMethod() {
  const navigate = useNavigate()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    validateUser()
  }, [])

  const validateUser = () => {
    if (!UserStorage.hasToken()) return
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
    })
  }

  const checkout = CheckoutStorage.get() || new CheckoutInput()

  const payByPix = () => {
    checkout.paymentMethodCode = "pix"

    const to = "/detalhes-de-pagamentos/pix"
    pay(checkout, to)
  }

  const payByCard = (card: CheckoutCardInput, token: string) => {
    checkout.paymentMethodCode = "credit-card"
    checkout.card = card
    checkout.token = token

    const to = "/detalhes-de-pagamentos/confirmado"
    pay(checkout, to)
  }

  const pay = (input: CheckoutInput, to = "") => {
    console.log("input", input)
    input.type = "charge"
    setIsLoading(true)
    OrderService.checkout(input)
      .then((order) => {
        console.log("order", order)
        navigate(`${to}/${order.id}?validator=${order.validator}`)
      })
      .catch((error) =>
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      )
      .finally(() => setIsLoading(false))
  }

  console.log("checkout", checkout)

  return (
    <Layout
      internal={true}
      menuSelected={"vehicle"}
      header={{
        title: "Pagamento",
        backTo: "/checkout/info",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Text fontSize="1rem" fontWeight="700" color="#2D3748">
          Selecione a forma de pagamento
        </Text>

        <VStack w="100%">
          <PaymentTypePix pay={payByPix} />
          <PaymentTypeCreditCard pay={payByCard} />
        </VStack>
      </Flex>
    </Layout>
  )
}
