import { User } from "../../entities"
import ApiClient from "../clients/ApiClient"

const apiClient = new ApiClient()

export class AuthRepository {
  public static async login(
    email: string,
    password: string,
    oneSignalToken: string
  ): Promise<string> {
    try {
      const data: any = { email, password, oneSignalToken }
      const res = await apiClient.DoRequest("POST", "/auth/login", data)
      console.log(res)
      return res.token
    } catch (error: any) {
      console.log("error", error)
      console.log("status", error?.response?.status)
      if (error?.response?.status === 401) {
        throw new Error("Usuário e senha não conferem.")
      }
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
      throw new Error("Erro inesperado.")
    }
  }

  public static async sendCode(
    id: number,
    type: string,
    token: string
  ): Promise<void> {
    const headers = { Authorization: `Bearer ${token}` }
    const data = { id, type }
    await apiClient.DoRequest("POST", "/auth/sendCode", data, headers)
  }

  public static async activateAccount(
    code: number,
    token: string
  ): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }
    const data = { code }
    const res = await apiClient.DoRequest(
      "POST",
      "/auth/activateAccount",
      data,
      headers
    )

    return res
  }

  public static async forgotPasswordWithEmail(
    email: string,
    type: string
  ): Promise<string> {
    const data = { email, type }
    return await apiClient.DoRequest("POST", "/auth/forgotPassword", data)
  }

  public static async forgotPasswordWithDocument(
    document: string,
    type: string
  ): Promise<string> {
    const data = { document, type }
    return await apiClient.DoRequest("POST", "/auth/forgotPassword", data)
  }

  public static async isValidRecoveryPasswordCode(
    email: string,
    document: string,
    code: number
  ): Promise<boolean> {
    const data = { email, document, code }
    return (
      (await apiClient.DoRequest(
        "POST",
        "/auth/validateRecoveryPasswordCode",
        data
      )) === true
    )
  }

  public static async recoveryPassword(
    email: string,
    document: string,
    code: number,
    newPassword: string,
    newPasswordConfirm: string
  ): Promise<boolean> {
    const data = { email, document, code, newPassword, newPasswordConfirm }
    return (
      (await apiClient.DoRequest("POST", "/auth/recoveryPassword", data)) ===
      true
    )
  }

  public static async getUserByToken(token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }
    const res = await apiClient.DoRequest(
      "GET",
      `/auth/getUserByToken`,
      {},
      headers
    )
    console.log("res", res)
    return new User(res)
  }

  public static async changePassword(
    password: string,
    newPassword: string,
    newPasswordConfirm: string,
    token: string
  ): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    const data = { password, newPassword, newPasswordConfirm }
    const res = await apiClient.DoRequest(
      "POST",
      "/auth/activateAccount",
      data,
      headers
    )

    return res === true
  }

  public static async getIp(): Promise<string> {
    const res = await apiClient.DoRequest("GET", "/auth/ip")
    return res.ip || ""
  }
}
