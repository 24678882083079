import { useEffect } from "react"
import {
  Link as ReactRouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  VStack,
  Link as ChakraLink,
  useDisclosure,
} from "@chakra-ui/react"
import "./AccessPage.scss"
import UserStorage from "../../util/userStorage"
import MobileStorage from "../../util/mobileStorage"
import TermsModal from "../../components/document/TermsModal"
import OneSignalStorage from "../../util/oneSignalStorage"
import { UserService } from "../../services"

export default function AccessPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const termsDisclosure = useDisclosure()
  const politicsDisclosue = useDisclosure()

  useEffect(() => {
    const oneSignal = searchParams.get("oneSignal")
    if (oneSignal) {
      OneSignalStorage.set(oneSignal)
    }

    const mobile = searchParams.get("mobile") === "1"
    if (mobile) {
      MobileStorage.setMobile()
      if (UserStorage.hasToken()) navigate("/area-logada")
    }

    validateUser()
  }, [searchParams])

  const validateUser = () => {
    if (!UserStorage.hasToken()) return
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
    })
  }

  return (
    <Flex
      w="100%"
      minH="100vh"
      height="100%"
      backgroundImage={{
        base: "/images/csg-small-banner.webp",
        sm: "/images/csg-small-banner.webp",
        md: "/images/csg-small-banner.webp",
        lg: "/images/csg-big-banner.webp",
      }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition={{
        base: "center",
        sm: "top",
        md: "top",
        lg: "center",
      }}
      justifyContent="flex-end"
      alignItems="center"
      direction="column"
      px="1rem"
      maxHeight="667px"
      sx={{
        "&": { "touch-action": "none" },
      }}
    >
      <Flex
        w="100%"
        maxW="1128px"
        mt="22rem"
        mb={{ base: "2rem", sm: "2rem", md: "2rem", lg: "1rem", xl: "2.5rem" }}
        justifyContent="space-between"
        direction={{ base: "column", sm: "column", md: "row", lg: "row" }}
        alignItems="center"
        gap="0.5rem"
      >
        <VStack
          w="100%"
          maxW={{ base: "100%", sm: "100%", md: "90%", lg: "100%" }}
          spacing={0}
          alignItems="flex-start"
        >
          <Text
            maxW={{ base: "300px", sm: "332px", md: "542px" }}
            as="span"
            fontSize={{
              base: "2.25rem",
              sm: "2.25rem",
              md: "3.15rem",
              lg: "3.35rem",
              xl: "3.75rem",
            }}
            fontWeight="600"
            color="#fff"
            lineHeight={{ base: "43.2px", sm: "60px" }}
            letterSpacing="-1.8px"
          >
            Caminhos que cuidam de
            <Text
              ml="0.75rem"
              as="span"
              fontSize={{
                base: "2.25rem",
                sm: "2.25rem",
                md: "3.15rem",
                lg: "3.35rem",
                xl: "3.75rem",
              }}
              fontWeight="600"
              color="#F26522"
              lineHeight={{ base: "43.2px", sm: "60px" }}
              letterSpacing="-1.8px"
            >
              ti
            </Text>
            .
          </Text>
          <Text
            maxW={{ base: "", sm: "500px", md: "631px" }}
            mt="0.5rem"
            color="#fff"
            fontSize={{
              base: "1.25rem",
              sm: "1.25rem",
              md: "1.5rem",
              lg: "1.75rem",
            }}
            fontWeight={{ base: "400" }}
            lineHeight="36px"
          >
            Facilitamos os pagamentos para que você tenha tempo para quem mais
            importa.
          </Text>
        </VStack>
        <Flex w="100%" direction="column" gap="0.25rem" mt="0.5rem">
          <ChakraLink
            w="100%"
            as={ReactRouterLink}
            to="/busca"
            _hover={{ textDecoration: "none" }}
          >
            <Button
              w="100%"
              size="lg"
              variant="solid"
              bg="#F26522"
              color="#fff"
              fontSize="1rem"
              fontWeight="700"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="0.5rem"
              _hover={{ bg: "#F26522", color: "#fff" }}
            >
              <Image
                src="/images/SearchWhiteIcon.png"
                alt="Ícone de lupa para busca"
              />
              Buscar meu veículo
            </Button>
          </ChakraLink>
          <Flex alignItems="center">
            <Box bg="#114B8F" w="49%" h="1px"></Box>
            <Text
              as="span"
              color="#CFD9EA"
              fontSize="1rem"
              fontWeight="500"
              px="1rem"
            >
              ou
            </Text>
            <Box bg="#114B8F" w="49%" h="1px"></Box>
          </Flex>
          <Flex w="100%" gap="1.25rem">
            <ChakraLink
              w="50%"
              as={ReactRouterLink}
              to="/cadastrar"
              _hover={{ textDecoration: "none" }}
            >
              <Button
                w="100%"
                size="lg"
                variant="solid"
                bg="#F7F8F9"
                color="#2D3748"
                fontSize="1rem"
                fontWeight="700"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                Criar conta
              </Button>
            </ChakraLink>
            <ChakraLink
              w="50%"
              as={ReactRouterLink}
              to="/login"
              _hover={{ textDecoration: "none" }}
            >
              <Button
                w="100%"
                size="lg"
                variant="solid"
                bg="#F7F8F9"
                color="#2D3748"
                fontSize="1rem"
                fontWeight="700"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                Login
              </Button>
            </ChakraLink>
          </Flex>
          <Flex direction="column">
            <Text
              fontSize="0.750rem"
              color="#F7F8F9"
              textAlign="center"
              mt="1rem"
            >
              Ao continuar, você concorda com os{" "}
              <Text
                color="#F26522"
                fontWeight="700"
                as="span"
                cursor="pointer"
                onClick={termsDisclosure.onOpen}
              >
                Termos e Condições
              </Text>{" "}
              da CSG e com a{" "}
              <Text
                color="#F26522"
                fontWeight="700"
                as="span"
                cursor="pointer"
                onClick={politicsDisclosue.onOpen}
              >
                Política de Privacidade
              </Text>
              .
            </Text>
            <TermsModal
              title="Política de Privacidade e Proteção de dados"
              url="/pdf/politica-privacidade-protecao-dados.pdf"
              isOpen={politicsDisclosue.isOpen}
              onOpen={politicsDisclosue.onOpen}
              onClose={politicsDisclosue.onClose}
              type="politics"
            />
            <TermsModal
              title="Termo e Condição de Uso"
              url="/pdf/termo-condicao-uso-app.pdf"
              isOpen={termsDisclosure.isOpen}
              onOpen={termsDisclosure.onOpen}
              onClose={termsDisclosure.onClose}
              type="terms"
            />
            {/* <MessageModal
              title="Atenção"
              message=""
              isOpen={messageDisclosure.isOpen}
              onOpen={messageDisclosure.onOpen}
              onClose={messageDisclosure.onClose}
            /> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
