import React, { Fragment, useEffect, useState } from "react"
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import {
  Flex,
  Text,
  Button,
  Image,
  Divider,
  FormControl,
  Input,
  useToast,
  Link as ChakraLink,
  Link,
  Box,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import { OrderService } from "../../services/csg"
import { Order } from "../../entities"
import UserStorage from "../../util/userStorage"
import Formatter from "../../util/formatter"
import { WarningIcon } from "@chakra-ui/icons"
import { format } from "date-fns"
import Loader from "../../components/Loader/Loader"

export default function PaymentScreen() {
  const toast = useToast()
  const params = useParams()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [validator, setValidator] = useState(0)
  const [counter, setCounter] = useState<number>(0)

  const [order, setOrder] = useState<Order | null>(null)
  const [loading, setLoading] = useState(false)

  const qrCodeHasExpired =
    order?.pix && order.pix?.expiresAt
      ? new Date() > order.pix.expiresAt
      : false

  useEffect(() => {
    if (params?.orderId) {
      const id = parseInt(params.orderId)
      if (searchParams.has("validator")) {
        const validator = parseInt(searchParams.get("validator") || "")
        load(id, validator)
        setValidator(validator)
      }
    }
  }, [params?.orderId, searchParams])

  useEffect(() => {
    if (order?.id) {
      const sec = Math.max(5000, counter * 1000)
      console.log("sec", sec)
      setTimeout(() => {
        updatePixStatus()
      }, sec)
    }
  }, [order, counter])

  const load = (id: number, validator: number) => {
    setLoading(true)
    OrderService.get(id, validator)
      .then((order: Order) => {
        if (order.pix) {
          const expiresAt = order.createdAt
          expiresAt.setHours(expiresAt.getHours() + 1)
          order.pix.expiresAt = expiresAt
          setOrder(order)
        }
      })
      .finally(() => setLoading(false))
  }

  const updatePixStatus = () => {
    if (!order) return

    OrderService.getStatus(order.id, validator).then((status) => {
      console.log("status", status)
      if (status === "paid" || status === "complete") {
        navigate("/detalhes-de-pagamentos/confirmado/" + order.id)
      }
    })

    if (counter < 10) {
      setCounter(counter + 1)
    } else {
      setCounter(5)
    }
  }

  return (
    <Layout
      internal={UserStorage.hasToken()}
      header={{
        title: "Pagamento via PIX",
        backTo: "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      {loading && <Loader />}
      {order && (
        <Flex
          w="100%"
          maxWidth="515px"
          minH="500px"
          direction="column"
          gap="1rem"
          fontFamily="Raleway"
        >
          {qrCodeHasExpired ? (
            <Fragment>
              <Box textAlign="center" mb="1rem">
                <WarningIcon w={50} h={50} color="#E53E3E" />
                <Text
                  color="#E53E3E"
                  fontSize="1.25rem"
                  fontWeight="700"
                  mt="1rem"
                >
                  O QR Code expirou
                </Text>
              </Box>

              <Button
                onClick={() => navigate("/checkout/info")}
                size="md"
                fontSize="0.875rem"
                fontWeight="600"
                bg="#00335B"
                color="#fff"
                _hover={{ bg: "#003f70", transition: "0.3s" }}
                w="100%" // Garante que o botão ocupe toda a largura
              >
                Gerar um novo QR Code
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Flex alignItems="center" gap="0.750rem">
                <Image
                  maxWidth="80px"
                  src="/images/WaitingClockIcon.png"
                  alt="Ícone do aguardo"
                />
                <Text
                  my="auto"
                  color="#2D3748"
                  fontSize="0.875rem"
                  fontWeight="700"
                  alignSelf="flex-start"
                >
                  Aguardando confirmação de pagamento
                </Text>
              </Flex>
              <Divider orientation="horizontal" my={2} bg="#CFD9EA" />
              <Flex justifyContent="space-between">
                <Flex direction="column" gap="0.25rem" font-family="Hauora">
                  <Text fontSize="0.750rem" fontWeight="500" color="#2D3748">
                    Total a pagar
                  </Text>
                  <Text fontSize="1.5rem" fontWeight="700" color="#2D3748">
                    {Formatter.formatCurrency(order.totals?.total || 0)}
                  </Text>
                </Flex>
                <Flex gap="0.5rem" alignItems="flex-start" maxW="150px">
                  <Image
                    mt="0.2rem"
                    w="18px"
                    h="18px"
                    bg="#7694FF"
                    borderRadius="50%"
                    src="/images/card-icon/Clock.svg"
                    alt="Ícone do aguardo"
                  />
                  {order.pix?.expiresAt && (
                    <Text fontSize="0.750rem" fontWeight="600">
                      QR Code válido ate{" "}
                      {format(order.pix?.expiresAt, "dd/MM/yyyy 'às' HH:mm")}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex direction="column" gap="1rem">
                <FormControl>
                  <Input
                    readOnly={true}
                    size="sm"
                    type="text"
                    value={order.pix?.copyPasteCode}
                    //onChange={}
                  />
                </FormControl>
                <Button
                  onClick={() => {
                    if (qrCodeHasExpired) {
                      toast({
                        title: "O QR Code já expirou",
                        status: "warning",
                        isClosable: true,
                      })
                      return
                    } else {
                      navigator.clipboard.writeText(
                        order.pix?.copyPasteCode || ""
                      )
                      toast({
                        title: `Código PIX copiado com sucesso!`,
                        status: "success",
                        isClosable: true,
                      })
                    }
                  }}
                  w="full"
                  size="md"
                  fontSize="0.875rem"
                  fontWeight="600"
                  bg="#00335B"
                  color="#fff"
                  _hover={{ bg: "#003f70", transition: "0.3s" }}
                  disabled={true}
                >
                  Copiar código PIX
                </Button>
              </Flex>

              <Flex direction="column" gap="1rem">
                <Text
                  color="#4A5568"
                  fontSize="0.750rem"
                  fontWeight="500"
                  textAlign="center"
                >
                  Acima, você encontrará o código PIX necessário para concluir o
                  pagamento. Basta copiá-lo e colá-lo em seu aplicativo de
                  pagamento.
                </Text>
                <Text
                  color="#4A5568"
                  fontSize="0.750rem"
                  fontWeight="500"
                  textAlign="center"
                >
                  Se preferir, você pode escanear o QR Code abaixo no seu
                  aplicativo de pagamento.
                </Text>
              </Flex>
              <Divider orientation="horizontal" my={2} bg="#CFD9EA" />
              <Flex justifyContent="center" alignItems="center">
                <Image
                  src={`${process.env.REACT_APP_API_URI}/order/pixImage/${order.id}?validator=${order.validator}`}
                  alt="Imagem do QR code do Pix"
                  w={228}
                  h={228}
                />
              </Flex>
            </Fragment>
          )}
        </Flex>
      )}
      {!UserStorage.hasToken() && (
        <Flex
          w="100%"
          maxWidth="515px"
          minH="100px"
          justifyContent="center"
          alignItems="flex-end"
        >
          <ChakraLink w="full" as={ReactRouterLink} to="/busca">
            <Button
              w="full"
              size="lg"
              fontSize="1rem"
              bg="#fff"
              color="#2B28A3"
              border="1px solid #2B28A3"
            >
              Realizar nova consulta
            </Button>
          </ChakraLink>
        </Flex>
      )}
    </Layout>
  )
}
