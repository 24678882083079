import React, { useEffect, useRef, useState } from "react"
import InputMask from "react-input-mask"
import Layout from "../../../components/layout/Layout/Layout"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Button,
  VStack,
  Link as ChakraLink,
  useToast,
  Select,
  useDisclosure,
} from "@chakra-ui/react"
import "./UserForm.scss"
import { States, User } from "../../../entities"
import { RoleService, UserService } from "../../../services"
import Loader from "../../../components/Loader/Loader"
import TermsModal from "../../../components/document/TermsModal"
import { ValidationInput } from "../../../util"
import { PostcodeService } from "../../../services/csg"
import Formatter from "../../../util/formatter"
import { isValid } from "date-fns"
import ReCAPTCHA from "react-google-recaptcha"

export default function UserForm() {
  const navigate = useNavigate()
  const toast = useToast()

  const recaptchaRef = useRef(null)
  const [token, setToken] = useState<string | null>(null)

  const [form, setForm] = useState<User>(new User())
  const [isLoading, setIsLoading] = useState(false)

  const termsDisclosure = useDisclosure()
  const politicsDisclosue = useDisclosure()

  useEffect(() => {
    loadCustomerRole()
    // eslint-disable-next-line
  }, [])

  const loadCustomerRole = () => {
    RoleService.getByCode("customer").then((role) =>
      setForm({ ...form, roleId: role.id })
    )
  }

  const save = async () => {
    const user = new User(form)

    if (!token) {
      toast({
        title: "Verifique o recaptcha para avançar",
        status: "error",
      })
      return
    }

    if (user.firstname.length <= 2) {
      toast({
        title: "Digite o primeiro nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.lastname.length <= 2) {
      toast({
        title: "Digite o sobrenome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.type === "PF") {
      if (user.document.length < 14) {
        toast({
          title: "Digite o CPF.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCPF(user.document)) {
          toast({
            title: "O CPF informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }

      if (user.birthDate === null) {
        toast({
          title: "Digite a data de nascimento.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        const now = new Date()
        const birthDate = new Date(user.birthDate)

        const diff =
          (now.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365)

        if (diff < 18) {
          toast({
            title:
              "Você deve ter pelo menos 18 anos para completar o cadastro.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
    } else {
      if (user.document.length < 18) {
        toast({
          title: "Digite o CNPJ.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCNPJ(user.document)) {
          toast({
            title: "O CNPJ informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
      if (user.companyName.length < 3) {
        toast({
          title: "Digite a Razão Social.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.businessName.length < 3) {
        toast({
          title: "Digite o Nome Fantasia.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.post.length < 3) {
        toast({
          title: "Digite o Cargo.",
          status: "error",
          duration: 2000,
        })
        return
      }
    }

    if (user.postcode.length < 9) {
      toast({
        title: "Digite o CEP.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address1.length < 3) {
      toast({
        title: "Digite o Endereço.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.city.length < 3) {
      toast({
        title: "Digite a Cidade.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.state.length !== 2) {
      toast({
        title: "Escolha o Estado.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address2.length < 3) {
      toast({
        title: "Digite o Bairro.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.addressNumber.length < 1) {
      toast({
        title: "Digite o Nº.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.password.length < 6) {
      toast({
        title: "A senha deve ter pelo menos 6 dígitos.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.password !== user.passwordConfirm) {
      toast({
        title: "A confirmação deve ser igual a senha.",
        status: "error",
        duration: 2000,
      })
      return
    }

    setIsLoading(true)
    await UserService.create(user, token)
      .then((user) => navigate(`/ativar-conta/sms`))
      .catch((error) => {
        console.log("error", error)
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => setIsLoading(false))
  }

  const searchPostcode = (postcode: string) => {
    if (postcode.length !== 9) return

    setIsLoading(true)
    PostcodeService.searchPostcode(postcode)
      .then((data) => {
        console.log("data", data)
        if (data?.erro === true) {
          toast({
            title: "CEP inválido",
            status: "error",
          })
          return
        }

        setForm({
          ...form,
          postcode: postcode,
          address1: data.logradouro,
          address2: data.bairro,
          city: data.localidade,
          state: data.uf,
          addressNumber: "",
          addressComplement: "",
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Criar conta
          </Text>
        </Flex>
        <FormControl isRequired>
          <FormLabel>Nome</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.firstname}
            onChange={(event) =>
              setForm({ ...form, firstname: event.target.value })
            }
            placeholder="Primeiro nome"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Sobrenome</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.lastname}
            onChange={(event) =>
              setForm({ ...form, lastname: event.target.value })
            }
            placeholder="Sobrenome"
          />
        </FormControl>

        <ButtonGroup variant="outline" spacing="1.5rem" w="100%">
          <Button
            w="50%"
            _focus={{ bg: "#F26522", color: "#fff" }}
            onClick={() => setForm({ ...form, type: "PF" })}
          >
            Pessoa Física
          </Button>
          <Button
            w="50%"
            _focus={{ bg: "#F26522", color: "#fff" }}
            onClick={() => setForm({ ...form, type: "PJ" })}
          >
            Pessoa Jurídica
          </Button>
        </ButtonGroup>

        {form.type && (
          <VStack spacing="1rem" mb="2rem">
            {form.type === "PJ" && (
              <FormLabel
                fontSize="1rem"
                fontWeight="700"
                color="#2D3748"
                alignSelf="flex-start"
              >
                Dados da empresa
              </FormLabel>
            )}
            <FormControl isRequired>
              <FormLabel>{form.type === "PF" ? "CPF" : "CNPJ"}</FormLabel>
              <Input
                as={InputMask}
                mask={
                  form.type === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
                }
                maskChar={null}
                size="lg"
                type="text"
                value={form.document}
                // blur={formatCnpjCpf}
                onChange={(event) =>
                  setForm({ ...form, document: event.target.value })
                }
                placeholder={
                  form.type === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
                }
              />
            </FormControl>
            {form.type === "PF" && (
              <FormControl isRequired>
                <FormLabel>Data de nascimento</FormLabel>
                <Input
                  size="lg"
                  type="date"
                  value={
                    form.birthDate &&
                    typeof form.birthDate === "string" &&
                    !isValid(form.birthDate)
                      ? form.birthDate
                      : Formatter.formatDateForInput(form.birthDate)
                  }
                  onChange={(event) => {
                    const newDate = event.currentTarget.value

                    setForm({ ...form, birthDate: newDate })
                  }}
                  placeholder="01/01/2000"
                />
              </FormControl>
            )}
            {form.type === "PJ" && (
              <>
                <FormControl isRequired>
                  <FormLabel>Razão social</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    value={form.companyName}
                    onChange={(event) =>
                      setForm({ ...form, companyName: event.target.value })
                    }
                    placeholder="Razão social"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Nome fantasia</FormLabel>
                  <Input
                    size="lg"
                    type="text"
                    value={form.businessName}
                    onChange={(event) =>
                      setForm({ ...form, businessName: event.target.value })
                    }
                    placeholder="Nome fantasia"
                  />
                </FormControl>
              </>
            )}
            <FormControl isRequired>
              <FormLabel>CEP</FormLabel>
              <Input
                as={InputMask}
                mask="99999-999"
                maskChar={null}
                size="lg"
                type="text"
                value={form.postcode}
                onChange={(event) => {
                  const postcode = event.target.value
                  setForm({ ...form, postcode: postcode })
                  searchPostcode(postcode)
                }}
                placeholder="00000-000"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Endereço</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.address1}
                onChange={(event) =>
                  setForm({ ...form, address1: event.target.value })
                }
                placeholder="Seu endereço"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Cidade</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.city}
                onChange={(event) =>
                  setForm({ ...form, city: event.target.value })
                }
                placeholder="Cidade"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Estado</FormLabel>
              <Select
                size="lg"
                placeholder="Estado"
                value={form.state}
                onChange={(event) =>
                  setForm({ ...form, state: event.target.value })
                }
              >
                {States.map((item: any) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Bairro</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.address2}
                onChange={(event) =>
                  setForm({ ...form, address2: event.target.value })
                }
                placeholder="Bairro"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Número</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.addressNumber}
                onChange={(event) =>
                  setForm({ ...form, addressNumber: event.target.value })
                }
                placeholder="Nº"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Complemento</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.addressComplement}
                onChange={(event) =>
                  setForm({ ...form, addressComplement: event.target.value })
                }
                placeholder="Ex.: apto 913"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Telefone</FormLabel>
              <Input
                as={InputMask}
                mask="(99) 99999-9999"
                maskChar={null}
                size="lg"
                type="text"
                value={form.phone}
                onChange={(event) =>
                  setForm({ ...form, phone: event.target.value })
                }
                placeholder="(00) 00000-0000"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>E-mail</FormLabel>
              <Input
                size="lg"
                type="email"
                value={form.email}
                onChange={(event) =>
                  setForm({ ...form, email: event.target.value })
                }
                placeholder="email@provedor.com"
                textTransform="lowercase"
              />
            </FormControl>
            {form.type === "PJ" && (
              <FormControl isRequired>
                <FormLabel>Cargo</FormLabel>
                <Input
                  size="lg"
                  type="text"
                  value={form.post}
                  onChange={(event) =>
                    setForm({ ...form, post: event.target.value })
                  }
                  placeholder="Ex.: Diretor Financeiro"
                />
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>Senha</FormLabel>
              <Input
                size="lg"
                type="password"
                value={form.password}
                onChange={(event) =>
                  setForm({ ...form, password: event.target.value })
                }
                placeholder="••••••••••••••"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Repetir senha</FormLabel>
              <Input
                size="lg"
                type="password"
                value={form.passwordConfirm}
                onChange={(event) =>
                  setForm({ ...form, passwordConfirm: event.target.value })
                }
                placeholder="••••••••••••••"
              />
            </FormControl>
          </VStack>
        )}
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        direction="column"
      >
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""}
          ref={recaptchaRef}
          onChange={(token: any) => {
            console.log("Captcha value:", token)
            setToken(token)
          }}
        />
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          bg="#00335B"
          color="#fff"
          _hover={{ bg: "#003f70", transition: "0.3s" }}
          onClick={save}
        >
          Criar conta
        </Button>
        <Flex direction="column">
          <Text
            fontSize="0.875rem"
            color="#2D3748"
            textAlign="center"
            mt="3"
            mb="5"
          >
            Ao continuar, você concorda com os{" "}
            <Text
              color="#3531C6"
              fontWeight="700"
              as="span"
              cursor="pointer"
              onClick={termsDisclosure.onOpen}
            >
              Termos e Condições de Uso
            </Text>{" "}
            da CSG e com a{" "}
            <Text
              color="#3531C6"
              fontWeight="700"
              as="span"
              cursor="pointer"
              onClick={politicsDisclosue.onOpen}
            >
              Política de Privacidade
            </Text>
            .
          </Text>
          <Text fontSize="0.875rem" color="#2D3748" textAlign="center">
            Já tem uma conta?
            <ChakraLink
              as={ReactRouterLink}
              to="/login"
              color="#3531C6"
              fontWeight="700"
              ml="1"
            >
              Login
            </ChakraLink>
          </Text>
        </Flex>
      </Flex>
      <TermsModal
        title="Política de Privacidade e Proteção de dados"
        url="/pdf/politica-privacidade-protecao-dados.pdf"
        isOpen={politicsDisclosue.isOpen}
        onOpen={politicsDisclosue.onOpen}
        onClose={politicsDisclosue.onClose}
        type="politics"
      />
      <TermsModal
        title="Termo e Condição de Uso"
        url="/pdf/termo-condicao-uso-app.pdf"
        isOpen={termsDisclosure.isOpen}
        onOpen={termsDisclosure.onOpen}
        onClose={termsDisclosure.onClose}
        type="terms"
      />
    </Layout>
  )
}
